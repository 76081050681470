import React, { useEffect, useState } from "react"

const Clock = ({ locales, options }: { locales: string | string[] | undefined, options: Intl.DateTimeFormatOptions | undefined }) => {
  const [date, setDate] = useState<Date>(),
    [time, setTime] = useState<string>(),
    updateTime = () => {
      const newDate = new Date()
      setDate(newDate)
      setTime(newDate.toLocaleString(locales, options))
    }

  useEffect(() => {
    updateTime()
    const intervalId = window.setInterval(
      updateTime,
      1000
    )

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    setTime(date?.toLocaleString(locales, options))
  }, [date, locales, options])

  return (
    <>{time}</>
  )
}

export default Clock
export { Clock }
