import React from "react"

export interface ContextInterface {
  clientWidth: number
  clientHeight: number
}

const Context = React.createContext({
  clientWidth: 0,
  clientHeight: 0,
})

export default Context
export { Context }