/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import * as React from "react"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"
import { Layout } from "./src/components/layout"
import "./src/css/index.css"

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider>{element}</PrismicPreviewProvider>
)

export const wrapPageElement = ({ element }) => <Layout>{element}</Layout>
