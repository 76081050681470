import React, { HTMLAttributes } from "react"

const Square = (props: HTMLAttributes<HTMLDivElement> & { forwardRef?: React.RefObject<HTMLDivElement> }) => {
  const { className: propsClassName, forwardRef: ref, ...rest } = props,
    className = `square col-span-4 sm:col-span-2 md:col-span-2 xl:col-span-1 ${
      propsClassName ? propsClassName : ``
    }`
  
  return (
    <div className={className} ref={ref} {...rest}>
      {props?.children}
    </div>
  )
}

export default Square
export { Square }
