import React, { useEffect, useState } from "react"
import { Context } from "../context"
import {
  Link,
  WrapPageElementNodeArgs,
  WrapPageElementBrowserArgs,
} from "gatsby"
import { GridContextInterface } from "../context/grid"
import { Helmet } from "react-helmet"
import { Square } from "./square"
import { useEventListener } from "../hooks/useEventListener"
import { Clock } from "./clock"
import { windowIfAvailable } from "../utilities/windowIfAvailable"

const Layout = ({
  children,
  pageContext,
  ...rest
}:
  | WrapPageElementNodeArgs<GatsbyTypes.PrismicGrid, GridContextInterface>
  | WrapPageElementBrowserArgs<GatsbyTypes.PrismicGrid, GridContextInterface>) => {
  const locales: string | string[] = `en-us`,
    options: Intl.DateTimeFormatOptions = {
      day: `numeric`,
      hour: `numeric`,
      minute: `2-digit`,
      second: `2-digit`,
      month: `long`,
      weekday: `long`,
      year: `numeric`,
    }

  // Observe client width
  const [clientWidth, setClientWidth] = useState(0),
    [clientHeight, setClientHeight] = useState(0)
  useEffect(() => {
    setClientWidth(document.documentElement.clientWidth)
    setClientHeight(document.documentElement.clientHeight)
  }, [])
  useEventListener(`resize`, (e) => {
    setClientWidth(document.documentElement.clientWidth)
    setClientHeight(document.documentElement.clientHeight)
  }, windowIfAvailable(), { passive: true })

  const nav = (
    <>
      <Square className="sm:h-0">
        <div className="p-3 sm:h-screen flex flex-col justify-between">
          <p className="mb-3">
            <Link to="/" className="no-underline">Spevack Loeb LLC</Link>
          </p>
          <p className="mb-3">A design office and consultancy in New York City’s Chinatown specializing in branding, creative direction, and web and ecommerce design, development, production, and management.</p>
          <p className="mb-3">Clients</p>
          <p className="mb-3">Aimé Leon Dore, Baron &amp; Baron, The Battery, David Zwirner Gallery, Estée Lauder Companies, Inditex, Kanye West, Luke Edward Hall, Marc Jacobs, Miles McEnery Gallery, Shell, SSENSE, Urby, Verishop, Witkoff, and others.</p>
          <p className="mb-3">Availability</p>
          <p className="mb-3">We are currently accepting inquiries for new projects. Please be in touch to discuss collaboration and for more information about our practice: <a href="mailto:info@studio.sl">info@studio.sl</a>.</p>
          <p className="mb-3">Careers</p>
          <p className="mb-3">Spevack Loeb seeks a design-focused mid/senior front-end developer with extensive experience in React.js, Typescript, testing, and SSG. For more information or to submit a resume please contact: <a href="mailto:cvs@studio.sl">cvs@studio.sl</a>.</p>
          <p className="hidden sm:block mt-auto text-xs">
            <Clock locales={locales} options={options} />
          </p>
        </div>
      </Square>
      <Square className="hidden xl:block m-3" />
      <Square className="hidden xl:block m-3" />
      <Square className="hidden xl:block m-3" />
    </>
  )

  return (
    <Context.Provider value={{ clientWidth, clientHeight }}>
      <Helmet>
        <html className="antialiased" />
        <title>Spevack Loeb</title>
        <meta
          property="description"
          content="A design office and consultancy in New York City’s Chinatown"
        />
      </Helmet>
      <nav className="sm:sticky xl:h-0 top-0 grid grid-cols-4 leading-tight tracking-wide">
        {nav}
      </nav>
      <style>{`
        :root {
          --scaleMargin: calc(24 / ${clientWidth});
          --scale: calc(var(--scaleColumn) - var(--scaleMargin));
        }
      `}</style>
      {children}
      <p className="block sm:hidden m-3 text-xs text-right leading-tight tracking-wide">
        <Clock locales={locales} options={options} />
      </p>
    </Context.Provider>
  )
}

export default Layout
export { Layout }
